import BugsnagPerformance, {
  type RoutingProvider,
  type StartRouteChangeCallback,
  type RouteChangeSpan
} from "@bugsnag/browser-performance"
import Bugsnag from "@bugsnag/js"
import type { HandleClientError } from "@sveltejs/kit"
import { version } from "./version"
import { PUBLIC_BUGSNAG_API_KEY, PUBLIC_ENVIRONMENT } from "$env/static/public"
import { uuidv4 } from "$helpers/utils"
import { navigating } from "$app/stores"

Bugsnag.start({
  apiKey: PUBLIC_BUGSNAG_API_KEY,
  appVersion: version,
  releaseStage: PUBLIC_ENVIRONMENT,
  enabledReleaseStages: ["preview", "staging", "production"]
})

class QSRoutingProvider implements RoutingProvider {
  listenForRouteChanges(startRouteChangeSpan: StartRouteChangeCallback) {
    let currentSpan: RouteChangeSpan | undefined
    navigating.subscribe((event) => {
      // event will be null after navigation is complete
      if (!event && currentSpan) {
        currentSpan.end(Date.now())
        currentSpan = undefined
      }

      if (event) {
        const url = event.to?.url
        if (url) {
          currentSpan = startRouteChangeSpan(url.href, "navigation", {
            startTime: Date.now()
          })
        }
      }
    })
  }

  resolveRoute(url: URL) {
    // start by removing route group prefixes. ie: `(auth admin)/admin`
    let path = url.pathname.replace(/^\/(\(.*\))/, "")

    // replace UUIDs with `[uuid]`
    path = path.replace(
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/gi,
      "[uuid]"
    )

    // special handling for the archive
    if (url.pathname.match(/^\/archive\/[0-9]{4}\/[0-9]{1,2}\/[0-9]{1,2}/)) {
      path = "/archive/[year]/[month]/[day]"
    }

    return path
  }
}

BugsnagPerformance.start({
  apiKey: PUBLIC_BUGSNAG_API_KEY,
  appVersion: version,
  releaseStage: PUBLIC_ENVIRONMENT,
  enabledReleaseStages: ["preview", "staging", "production"],
  routingProvider: new QSRoutingProvider(),
  bugsnag: Bugsnag
})

const handleError: HandleClientError = ({
  error,
  /* event,*/ status,
  message
}) => {
  const errorId = uuidv4()
  const errMessage = error instanceof Error ? error.message : message

  if (![400, 404].includes(status)) {
    try {
      const errorOptions = {
        errorId,
        data: {
          error,
          status
        }
      }

      const err = error instanceof Error ? error : new Error(errMessage)

      Bugsnag.notify(err, (event) => {
        event.severity = "error"
        event.addMetadata("extraContext", errorOptions)
      })
    } catch (e) {
      console.error(e, error, status, message)
    }
  }

  return {
    message: "An unexpected error has occurred. Please try again later.",
    errorId
  }
}

export { handleError }
